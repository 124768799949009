import { render, staticRenderFns } from "./step-typein.vue?vue&type=template&id=026a639c&scoped=true&"
import script from "./step-typein.vue?vue&type=script&lang=js&"
export * from "./step-typein.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "026a639c",
  null
  
)

export default component.exports