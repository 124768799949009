<template>
  <t-alarm
    class="alarm-editor"
    :intro-text="data.introText"
    :wrong-message="data.wrongMessage"
    :right-message="data.rightMessage"
    :comment="data.comment"
  >
    <template v-slot:control="slotProps">
      <t-alarm-control @prev="slotProps.prev" @next="slotProps.next"> </t-alarm-control>

      {{ slotProps.step }}
    </template>
  </t-alarm>
</template>

<script>
import TAlarm from "@/components/type/alarm/t-alarm";
import TAlarmControl from "@/components/type/alarm/t-alarm-control";

export default {
  name: "StepAlarm",
  components: { TAlarmControl, TAlarm },
  props: ["data"],
};
</script>

<style lang="sass">
.alarm-editor .step-alarm__inner
  position: relative
  padding: 10px
  width: 100%
  height: 700px
  border: 1px solid #1f5bff
  margin-bottom: 20px
  overflow: hidden
  border-radius: 10px
</style>
