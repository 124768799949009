<template>
  <t-message :data="data"></t-message>
</template>

<script>
import TMessage from "@/components/type/t-message";
export default {
  name: "StepAutoTypeIn",
  components: { TMessage },
  props: ["data"],
};
</script>

<style scoped></style>
