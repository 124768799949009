<template>
  <div v-if="stickers" class="sticker-choose">
    <div
      v-for="sticker in stickers"
      :key="sticker.id"
      :class="['sticker-choose__item', { '--active': isActive(sticker.id) }]"
      @click="setSticker(sticker.id)"
    >
      <v-sticker :sticker="sticker.id"></v-sticker>
    </div>
  </div>
</template>

<script>
import api from "@/api";
import VSticker from "@/components/basic/v-sticker";

export default {
  name: "VStickerChoose",
  components: { VSticker },
  props: {
    multiple: {
      default: false,
    },
  },
  data: function() {
    return {
      chooseSticker: null,
      stickers: null,
    };
  },
  mounted() {
    if (this.multiple) {
      this.chooseSticker = [];
    }
    this.loadStickers();

    this.stickers = this.value;
  },
  methods: {
    isActive: function(id) {
      if (this.chooseSticker === null) return false;

      if (this.chooseSticker !== null && typeof this.chooseSticker === "object") return this.chooseSticker.includes(id);
      else return this.chooseSticker === id;
    },
    loadStickers: async function() {
      this.stickers = await api.getStickers();
    },
    setSticker: function(id) {
      if (this.multiple) {
        if (!this.chooseSticker.includes(id)) {
          this.chooseSticker.push(id);
        } else {
          this.chooseSticker.splice(this.chooseSticker.indexOf(id), 1);
        }
      } else {
        this.chooseSticker = id;
      }

      this.$emit("input", this.chooseSticker);
    },
  },
};
</script>

<style lang="sass">
.sticker-choose
  background: #fff
  overflow: hidden
  display: flex
  flex-wrap: wrap
  padding: 2px
  &__item
    width: 20%
    margin: 1px
    max-width: 120px
    padding: 5px
    border: 2px solid #ffffff
    background: #f2f2f2
    &:hover
      cursor: pointer
      border-color: #92cd9c
    &.--active
      border-color: #1aa15b
    img
      width: 100%
</style>
