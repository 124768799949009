<template>
  <div>
    <h3>Отправка стикера</h3>
    <div class="mb-2">Картинка:</div>
    <v-sticker-choose v-model="form.img" class="mb-3"></v-sticker-choose>
    <v-input v-model="form.time" label="Время отправки"></v-input>
    <v-input v-model="form.direction" label="Направление (in|out)"></v-input>
    <v-input v-if="form.direction === 'in'" v-model="form.delayWrite" label="Время написания (печатает...)"></v-input>
    <v-input
      v-if="form.direction === 'out'"
      v-model="form.delayDelivery"
      label="Задержка доставки (до 2х галочек)"
    ></v-input>
    <v-input
      v-if="form.direction === 'out'"
      v-model="form.delayRead"
      label="Задержка на прочтение (до синих галочек)"
    ></v-input>
    <v-input v-model="form.delayNextStep" label="Задержка до следующего шага"></v-input>
  </div>
</template>

<script>
import VInput from "@/components/basic/v-input";
import VStickerChoose from "@/components/basic/v-sticker-choose";
export default {
  name: "FormSticker",
  components: { VStickerChoose, VInput },
  props: {
    form: Object,
  },
};
</script>

<style scoped></style>
