<template>
  <div class="mb-3 multi-textarea">
    <label class="form-label">{{ label }}</label>
    <div v-for="(item, idx) in selfValues" :key="idx" class="row">
      <div class="col">
        <textarea
          :key="idx"
          class="form-control mb-2"
          rows="3"
          :value="item"
          @input="setValue(idx, $event.target.value)"
        >
        </textarea>
        <div class="fa-pull-right px-3 mb-2 btn btn-sm btn-danger" @click="selfValues.splice(idx, 1)">Удалить</div>
      </div>
    </div>

    <span class="btn btn-sm btn-primary" @click="selfValues.push('')">Добавить</span>
  </div>
</template>

<script>
export default {
  name: "VTextareaMultiple",
  props: {
    label: String,
    value: Array,
  },
  data: function() {
    return {
      selfValues: [],
    };
  },
  mounted() {
    this.selfValues = this.value || [""];
  },
  methods: {
    setValue: function(idx, value) {
      this.$set(this.selfValues, idx, value);
      this.$emit("input", this.selfValues);
    },
  },
};
</script>

<style lang="sass">
.multi-textarea
  border-bottom: 1px solid #c6c6c6
  border-top: 1px solid #c6c6c6
  padding: 20px 20px
  background: #e7e7e7
</style>
