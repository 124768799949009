<template>
  <div class="mb-2">
    <label v-if="label" class="form-label">{{ label }}</label>
    <input type="email" class="form-control" :value="value" @input="e => $emit('input', e.target.value)" />
  </div>
</template>

<script>
export default {
  name: "VInput",
  props: ["value", "label"],
};
</script>

<style lang="sass">
.form-control
  border: none
  outline: none
  box-shadow: none !important
  font-size: 14px
  border-radius: 2px
.form-label
  margin-bottom: 3px
  font-size: 13px
input.form-control
  height: 25px
  line-height: 25px
</style>
