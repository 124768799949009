<template>
  <div>
    <h3>Переход на воспоминания</h3>
    <v-input v-model="form.idMemory" label="ID Воспоминания"></v-input>
  </div>
</template>

<script>
import VInput from "@/components/basic/v-input";
export default {
  name: "FormNotification",
  components: { VInput },
  props: {
    form: Object,
  },
};
</script>

<style scoped></style>
