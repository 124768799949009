<template>
  <div class="alarm__control">
    <div class="alarm__control-prev" @click="$emit('prev')">Prev</div>
    <div class="alarm__control-next" @click="$emit('next')">Next</div>
  </div>
</template>

<script>
export default {
  name: "TAlarmControl",
};
</script>

<style lang="sass">
.alarm__control
  display: flex
  justify-content: space-between
  position: relative
  &-prev, &-next
    background: #1aa15b
    padding: 5px 10px
    &:hover
      cursor: pointer
      background: #1f5bff
</style>
