<template>
  <div class="mb-3">
    <label v-if="label" class="form-label">{{ label }}</label>
    <textarea class="form-control" :rows="rows" :value="value" @input="e => $emit('input', e.target.value)"></textarea>
  </div>
</template>

<script>
export default {
  name: "VTextarea",
  props: {
    label: String,
    value: String,
    rows: {
      type: Number,
      default: 3,
    },
  },
};
</script>

<style scoped></style>
