<template>
  <t-sticker :data="data"></t-sticker>
</template>

<script>
import TSticker from "@/components/type/t-sticker";
export default {
  name: "StepSticker",
  components: { TSticker },
  props: ["data"],
};
</script>

<style scoped></style>
