<template>
  <t-notification name="Маруся" :app="data.app" :text="data.text"></t-notification>
</template>

<script>
import TNotification from "@/components/type/t-notification";
export default {
  name: "StepNotification",
  components: { TNotification },
  props: ["data"],
};
</script>

<style lang="sass"></style>
