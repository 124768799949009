<template>
  <div>
    <h3>Ввод текста пользователем</h3>
    <v-input v-model="form.time" label="Время отправки"></v-input>
    <v-input v-model="form.delayDelivery" label="Задержка доставки (до 2х галочек)"></v-input>
    <v-input v-model="form.delayRead" label="Задержка на прочтение (до синих галочек)"></v-input>
    <v-input v-model="form.delayNextStep" label="Задержка до следующего шага"></v-input>
  </div>
</template>

<script>
import VInput from "@/components/basic/v-input";
export default {
  name: "FormTypeIn",
  components: { VInput },
  props: {
    form: Object,
  },
};
</script>

<style scoped></style>
