<template>
  <div class="">
    <div class="editor__tools">
      <h2>Редактор чата</h2>
      <button type="button" class="btn btn-primary btn-sm" @click="create">Добавить</button>
      <button v-if="!isSaved" type="button" class="btn btn-success btn-sm" @click="save">Сохранить</button>
    </div>
    <div class="editor row">
      <div class="col editor__list">
        <div
          v-for="(story) in stories"
          :key="story.id"
          :class="['editor__list-item', { '--active': story === currentStory }]"
        >
          <span class="editor__list-item__link" @click="select(story.id)">
            <span class="editor__list-item__index">{{ story.sort }}</span>
            <span class="editor__list-item__id">{{ story.idBranch }}</span>
            {{ story.name }}
          </span>
          <div class="editor__list-item__action">
            <span @click="trash(story.id)"><i class="far fa-trash-alt"></i></span>
          </div>
        </div>
      </div>
      <div class="col editor__chat">
        <template v-if="currentStory">
          <div class="editor__form-new row">
            <div class="editor__form-new__item">Добавить шаг</div>
            <div class="editor__form-new__item editor__form-new__link" @click="addStep('message')">
              <i class="far fa-comments"></i>
            </div>
            <div class="editor__form-new__item editor__form-new__link" @click="addStep('auto-type-in')">
              <i class="far fa-comment-dots"></i>
            </div>
            <div class="editor__form-new__item editor__form-new__link" @click="addStep('type-in')">
              <i class="far fa-comment-dots"></i>
            </div>
            <div class="editor__form-new__item editor__form-new__link" @click="addStep('sticker')">
              <i class="far fa-image"></i>
            </div>
            <div class="editor__form-new__item editor__form-new__link" @click="addStep('sticker-pack')">
              <i class="far fa-images"></i>
            </div>
            <div class="editor__form-new__item editor__form-new__link" @click="addStep('notification')">
              <i class="far fa-bell"></i>
            </div>
            <div class="editor__form-new__item editor__form-new__link" @click="addStep('alarm')">
              <i class="fas fa-exclamation-triangle"></i>
            </div>
          </div>

          <div class="editor__chat-steps">
            <div
              v-for="(step, idx) in currentStory.steps"
              :key="step.id"
              :class="['step__item', { '--active': currentStep && step.id === currentStep.id }]"
            >
              <div class="step__item-info" @click="selectStep(step.id)">
                <div><b>ID:</b>{{ step.id }} <b>Тип:</b> {{ step.type }}</div>
                <!--                <b>Задержки</b>-->
                <!--                написание: {{ step.delayWrite }}-->
                <!--                доставка: {{ step.delayDelivery }}-->
                <!--                чтение: {{ step.delayRead }}-->
                <!--                след шаг: {{ step.delayRead }}-->
                <div class="step__tools">
                  <span class="step__tools-item" @click="upStep(idx)"><i class="fas fa-arrow-up"></i></span>
                  <span class="step__tools-item" @click="downStep(idx)"><i class="fas fa-arrow-down"></i></span>
                  <span class="step__tools-item" @click="deleteStep(idx)"><i class="far fa-trash-alt"></i></span>
                </div>
              </div>
              <div class="step__item-content">
                <component :is="`step-${step.type}`" :data="step"></component>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          Выбирите ветку
        </template>
      </div>
      <div v-if="currentStory" class="col editor__form pt-3">
        <template v-if="currentStep">
          <component :is="`form-${currentStep.type}`" :form="currentStep"></component>
        </template>
        <div v-else>
          <div class="editor__chat-name row">
            <v-input v-model="currentStory.name" label="Название ветки"></v-input>
            <v-input v-model="currentStory.idBranch" label="ID ветки"></v-input>
            <v-input v-model="currentStory.main" label="Основная"></v-input>
            <template v-if="currentStory.main">
              <v-input v-model.number="currentStory.sort" label="Сортировка"></v-input>
              <v-input v-model="currentStory.listText" label="Текст в листинге"></v-input>
              <v-input v-model="currentStory.introText" label="Вступительный текст"></v-input>
              <v-input v-model="currentStory.shareText" label="Текст для шаринга"></v-input>
              <v-input v-model="currentStory.img" label="Номер для картинок"></v-input>
              <v-input v-model="currentStory.title" label="Title"></v-input>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api";
import VInput from "@/components/basic/v-input";
import VTextarea from "@/components/basic/v-textarea";
import StepMessage from "@/components/editor/step-message";
import StepNotification from "@/components/editor/step-notification";
import FormMessage from "@/components/editor/form/form-message";
import StepSticker from "@/components/editor/step-sticker";
import StepTypeIn from "@/components/editor/step-typein";
import FormSticker from "@/components/editor/form/form-sticker";
import StepAlarm from "@/components/editor/step-alarm";
import FormTypeIn from "@/components/editor/form/form-typeIn";
import FormAlarm from "@/components/editor/form/form-alarm";
import FormNotification from "@/components/editor/form/form-notification";
import StepStickerPack from "@/components/editor/step-sticker-pack";
import FormStickerPack from "@/components/editor/form/form-sticker-pack";
import FormAutoTypeIn from "@/components/editor/form/form-auto-typeIn";
import StepAutoTypeIn from "@/components/editor/step-auto-typein";

export default {
  name: "EditorChatPage",
  components: {
    StepAutoTypeIn,
    FormAutoTypeIn,
    FormStickerPack,
    StepStickerPack,
    FormNotification,
    FormAlarm,
    FormTypeIn,
    FormMessage,
    FormSticker,
    StepAlarm,
    StepMessage,
    StepNotification,
    StepSticker,
    StepTypeIn,
    VTextarea,
    VInput,
  },
  data: function() {
    return {
      stories: null,
      currentStory: null,
      currentStep: {},
      isSaved: true,
    };
  },
  watch: {
    currentStory: {
      handler: function(val, oldVal) {
        if (!val) return false;
        if (oldVal === null) return false;
        if (oldVal.id !== val.id) return false;

        this.isSaved = false;
      },
      deep: true,
    },
    currentStep: function(val) {
      if (!val) {
        this.component = null;
        return false;
      }
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    load: async function() {
      this.stories = await api.load();
    },
    select: async function(id) {
      await this.save();

      this.currentStory = this.stories.find(i => i.id === id);
      this.currentStep = null;
    },
    create: async function() {
      await this.save();
      await api.create();
      await this.load();
    },
    selectStep: function(id) {
      this.currentStep = this.currentStory.steps.find(i => i.id === id);
    },
    trash: async function(id) {
      const confirm =  prompt('Для удаления напишите: DELETE')

      if (confirm !== "DELETE") return false;

      const status = await api.delete(id);
      if (status !== 200) return false;
      await this.load();

      if (this.currentStory.id === id) this.currentStory = null;
    },
    addStep: function(type, idx = null) {
      this.currentStory.steps.push({
        id: this.currentStory.steps.length + 1,
        type: type,
      });
    },
    save: async function() {
      if (this.isSaved && this.currentStory === null) return false;

      const status = await api.save(this.currentStory.id, this.currentStory);

      if (status === 200) this.isSaved = true;
    },
    reindexStep: function() {
      for (let i = 0; i < this.currentStory.steps.length; i++) {
        this.currentStory.steps[i].id = i + 1;
      }
    },
    deleteStep: function(idx) {
      this.currentStory.steps.splice(idx, 1);
      this.reindexStep();
    },
    upStep: function(idx) {
      const step = this.currentStory.steps.splice(idx, 1);
      this.currentStory.steps.splice(idx - 1, 0, step[0]);
      this.reindexStep();
    },
    downStep: function(idx) {
      const step = this.currentStory.steps.splice(idx, 1);
      this.currentStory.steps.splice(idx + 1, 0, step[0]);
      this.reindexStep();
    },
  },
};
</script>

<style lang="sass">

.step
  &__item
    padding: 0 0 10px
    //border: 1px dashed #d2d2d2
    position: relative
    display: flex
    justify-content: space-between

    &-content
      min-width: 400px

    &.--active
      .step__item-info
        background: rgba(190, 229, 189, .4)

    &-info
      margin-right: 20px
      width: 100%
      font-size: 11px
      background: rgba(255, 255, 255, 0.4)
      padding: 5px 10px
      color: #444
      margin-bottom: 5px
      display: flex
      flex-direction: column

      &:hover
        cursor: pointer
        background: rgba(208, 224, 208, 0.4)

  &__tools
    font-size: 15px
    margin-top: 10px

    &-item
      opacity: 0.5
      margin-right: 10px

      &:hover
        opacity: 1
        cursor: pointer


.editor

  &__form
    background: #f2f2f2
    display: flex
    flex-direction: column
    border-right: 1px solid #dbdbdb
    overflow-y: scroll
    padding-bottom: 120px
    height: 100%
    //justify-content: space-between

    &-new
      display: flex
      flex-direction: row
      background: #fff
      border-bottom: 1px solid #dbdbdb
      margin-bottom: 20px

      &__item
        width: auto
        background: #fff
        padding: 10px
        //border: 1px solid #dbdbdb
        border-right: 1px solid #dbdbdb

      &__link:hover
        cursor: pointer
        background: #fafafa


  &__chat
    background: url('/img/chat__bg.jpg') center center repeat
    background-size: 70%
    min-width: 600px
    max-width: 700px !important
    border-right: 1px solid #dbdbdb
    height: 100%
    display: flex
    flex-direction: column
    //padding: 0 30px 0 20px

    &-name
      background: #f2f2f2
      padding-top: 10px
      border-bottom: 1px solid #dbdbdb

    &-steps
      overflow-y: auto
      padding: 0 5px 20px
//overflow: scroll
</style>
