<template>
  <div class="sticker-pack">
    <div v-for="(sticker, idx) in data.stickers" :key="idx" class="sticker-pack__item">
      <v-sticker :sticker="sticker"></v-sticker>
    </div>
  </div>
</template>

<script>
import VSticker from "@/components/basic/v-sticker";
export default {
  name: "StepStickerPack",
  components: { VSticker },
  props: ["data"],
};
</script>

<style lang="sass">
.sticker-pack
  display: flex
  flex-wrap: wrap
  &__item
    width: 80px
</style>
