<template>
  <div>
    <h3>Конвертор</h3>
    <v-textarea-multiple v-model="form.introText" label="Комментарий психолога"></v-textarea-multiple>
    <v-textarea v-model="form.wrongMessage" label="Неправильное сообщение"></v-textarea>
    <v-textarea-multiple v-model="form.rightMessage" label="Правильное сообщение"></v-textarea-multiple>
    <v-textarea-multiple v-model="form.comment" label="Почему так?"></v-textarea-multiple>
    <v-textarea v-model="form.description" label="Почему так?"></v-textarea>
  </div>
</template>

<script>
import VTextarea from "@/components/basic/v-textarea";
import VTextareaMultiple from "@/components/basic/v-textarea-multiple";
export default {
  name: "FormAlarm",
  components: { VTextareaMultiple, VTextarea },
  props: {
    form: Object,
  },
};
</script>

<style scoped></style>
